import servicesUtils from '../utils/service';
import CryptoJS from 'crypto-js';

export const userServices = {
    encryptData(data, key) {
        const keyBytes = CryptoJS.enc.Hex.parse(key);
        const iv = CryptoJS.lib.WordArray.random(16); // Generating a random IV

        const encrypted = CryptoJS.AES.encrypt(data, keyBytes, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        const combined = iv.toString(CryptoJS.enc.Hex) + encrypted.ciphertext.toString(CryptoJS.enc.Hex);
        return combined;
    },
    async checkEmail(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('checkEmail')}` + '?' + servicesUtils.queryParams(payload)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get checkEmail err: ${err}`)
            throw err
        }
    },
    async saveUserData(d) {
        try {
            const url = `${servicesUtils.resourceUrl('user')}`
            const data = await servicesUtils.post(url, d)
            return data
        } catch (err) {
            console.log(`failed to save user data, err: ${err}`)
            throw err
        }
    },
    async verifySignUpCode(code) {
        try {
            const url = `${servicesUtils.resourceUrl('verifySignUpCode')}`.replace(':code', code)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get verifySignUpCode err: ${err}`)
            throw err
        }
    },
    async checkForgetPwdCode(code) {
        try {
            const url = `${servicesUtils.resourceUrl('forgetPwdCheckCode')}`.replace(':code', code);
            const data = await servicesUtils.post(url, {})
            return data
        } catch (err) {
            console.log(`failed to checkForgetPwdCode err: ${err}`)
            throw err
        }
    },
    async forgetPwdChangePwd(code, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('forgetPwdChangePwd')}`.replace(':code', code);
            const data = await servicesUtils.post(url, payload)
            return data
        } catch (err) {
            console.log(`failed to forgetPwdChangePwd err: ${err}`)
            throw err
        }
    },

    async getUserList(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('consoleUserList')}` + '?' + servicesUtils.queryParams(payload)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getUserList err: ${err}`)
            throw err
        }
    },

    async checkSchool(id, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('consoleUserCheckSchool')}`.replace(':id', id);
            const data = await servicesUtils.post(url, payload)
            return data
        } catch (err) {
            console.log(`failed to checkSchool err: ${err}`)
            throw err
        }
    },
    async getEditAccountLink() {
        try {
            const url = `${servicesUtils.resourceUrl('editAccountLink')}`;
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to editAccountLink err: ${err}`)
            throw err
        }
    },
    async refreshUserInfo() {
        try {
            const url = `${servicesUtils.resourceUrl('refreshUserInfo')}`;
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to refreshUserInfo err: ${err}`)
            throw err
        }
    },
    async getMyVouchers(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('getMyVouchers')}`
                + '?' + servicesUtils.queryParams(payload);
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to getMyVouchers err: ${err}`)
            throw err
        }
    },
    async redeemVoucher(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('redeemVoucher')}`;
            const data = await servicesUtils.post(url, payload)
            return data
        } catch (err) {
            console.log(`failed to redeemVoucher err: ${err}`)
            throw err
        }
    },
    async useVoucher(id) {
        try {
            const url = `${servicesUtils.resourceUrl('useVoucher')}`
                .replace(':id', id);
            const data = await servicesUtils.post(url);
            return data;
        } catch (err) {
            console.log(`failed to useVoucher err: ${err}`)
            throw err
        }
    },
    async getMyInviteCode() {
        try {
            const url = `${servicesUtils.resourceUrl('getMyInviteCode')}`;
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to getMyInviteCode, err: ${err}`)
            throw err
        }
    },
    async getMyInvitedFriends(page) {
        try {
            const url = `${servicesUtils.resourceUrl('getMyInvitedFriends')}` +
                '?' + servicesUtils.queryParams({ page: page });
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to getMyInvitedFriends, err: ${err}`)
            throw err
        }
    },
    async changeMode() {
        try {
            const url = `${servicesUtils.resourceUrl('changeMode')}`;
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to changeMode, err: ${err}`)
            throw err
        }
    },
    async basicInfoSetting(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('userBasicInfoSetting')}`;
            const data = await servicesUtils.post(url, payload);
            return data;
        } catch (err) {
            console.log(`failed to basicInfoSetting err: ${err}`)
            throw err
        }
    },
    async schoolSetting(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('userSchoolSetting')}`;
            const data = await servicesUtils.post(url, payload);
            return data;
        } catch (err) {
            console.log(`failed to userSchoolSetting err: ${err}`)
            throw err
        }
    },
    async getFullEmail() {
        try {
            const url = `${servicesUtils.resourceUrl('emailValidate')}`
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed in getFullEmail err: ${err}`)
            throw err
        }
    },
    async changeLanguage(language) {
        try {
            const url = `${servicesUtils.resourceUrl('changeLanguage')}`
                .replace(':lang', language);
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed in changeLanguage err: ${err}`)
            throw err
        }
    },
    async getKey() {
        try {
            const url = `${servicesUtils.resourceUrl('getKey')}`;
            const data = await servicesUtils.get(url);
            return data
        } catch (err) {
            console.log(`failed in getKey err: ${err}`)
            throw err
        }
    },
    async changePwd(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('changePwd')}`;
            const data = await servicesUtils.post(url, payload);
            return data
        } catch (err) {
            console.log(`failed in changePwd err: ${err}`)
            throw err
        }
    },
    async bindingCheck() {
        try {
            const url = `${servicesUtils.resourceUrl('bindingCheck')}`;
            const data = await servicesUtils.get(url);
            return data
        } catch (err) {
            console.log(`failed in bindingCheck err: ${err}`)
            throw err
        }
    },
    async forgetPwd(validateWay, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('forgetPWD')}`
                .replace(':validateWay', validateWay);
            const data = await servicesUtils.post(url, payload);
            return data
        } catch (err) {
            console.log(`failed in changePwd err: ${err}`)
            throw err
        }
    },
    async resetPwd(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('resetPwd')}`;
            const data = await servicesUtils.post(url, payload);
            return data
        } catch (err) {
            console.log(`failed in resetPwd err: ${err}`)
            throw err
        }
    },
    async checkResetPwd(code) {
        try {
            const url = `${servicesUtils.resourceUrl('checkResetPwd')}`
                .replace(':code', code);
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed in checkResetPwd err: ${err}`)
            throw err
        }
    },
    async updateAvatar(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('updateAvatar')}`;
            const data = await servicesUtils.postFormData(url, payload);
            return data
        } catch (err) {
            console.log(`failed in resetPwd err: ${err}`)
            throw err
        }
    },
}
import Cookies from 'js-cookie';

function isLocalStorageAvailable() {
    // return false;
    try {
        // return localStorage.getItem('disable') != '1';
        const testKey = 'test';
        localStorage.setItem(testKey, '1');
        const value = localStorage.getItem(testKey);
        if (value !== '1') {
            return false;
        }
        localStorage.removeItem(testKey);
        return true;
    } catch (error) {
        return false;
    }
}

function setCookie(name, value, days) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = `${name}=${value || ""}${expires}; path=/; Secure; SameSite=Strict`;
}
export function checkSharedLangCookie() {
    const key = process.env.VUE_APP_SHARED_COOKIE_KEY_LANGUAGE;
    console.log('checkSharedLangCookie, key: ', key);
    let sharedCookie = Cookies.get(key);
    console.log('sharedCookie in checkSharedLangCookie: ', sharedCookie);
    const allCookies = Cookies.get();
    console.log('allCookies in checkSharedLangCookie: ', allCookies);
    if (!sharedCookie) {
        return false;
    }
    const locale = getItem('locale');
    if (sharedCookie !== 'en' && sharedCookie.slice(0, 2).toLowerCase() !== 'zh') {
        if (locale != 'en') {
            return false;
        }
        return true;
    } else if (locale != sharedCookie){
        return false;
    }
    return true;
}

export function setSharedLangCookie(lang, overwrite = false) {
    const domainName = process.env.VUE_APP_SHARED_COOKIE_DOMAIN;
    const key = process.env.VUE_APP_SHARED_COOKIE_KEY_LANGUAGE;
    let sharedCookie = Cookies.get(key);
    console.log('setSharedLangCookie, key: ', key);
    console.log('sharedCookie: ', sharedCookie);
    console.log('lang: ', lang);
    console.log('domainName: ', domainName);
    const allCookies = Cookies.get();
    console.log('allCookies in setSharedLangCookie: ', allCookies);
    if (!sharedCookie) {
        console.log('create sharedCookie');
        const setCookieResult = Cookies.set(key, lang, { domain: `.${domainName}` });
        console.log('setCookieResult: ', setCookieResult);
    } else if (sharedCookie !== lang) {
        console.log('update sharedCookie');
        ///如果是前台才有的語言要看是否要更新
        if (sharedCookie != 'en' && sharedCookie.slice(0, 2).toLowerCase() != 'zh' && !overwrite) {
            return;
        }
        // Cookies.set(key, lang);
        Cookies.set(key, lang, { domain: `.${domainName}` });
    }
}

export function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length);
    }
    return null;
}

function deleteCookie(name) {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Secure; SameSite=Strict`;
}

function clearCookies() {
    const cookies = document.cookie.split(";");

    for (let cookie of cookies) {
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; Secure; SameSite=Strict";
    }
}

export function setItem(key, value) {
    // console.log(`setItem - key: ${key}, value: ${value}`);
    if (isLocalStorageAvailable()) {
        // console.log('set in local storage');
        localStorage.setItem(key, value);
    } else {
        // console.log('set in cookie');
        setCookie(key, value, 7); // Default to 7 days expiry for cookies
    }
}

export function getItem(key) {
    // console.log('getItem - key: ', key);

    if (isLocalStorageAvailable()) {
        // console.log('get from local storage');
        return localStorage.getItem(key);
    } else {
        // console.log('get from cookie');
        return getCookie(key);
    }
}

export function removeItem(key) {
    if (isLocalStorageAvailable()) {
        localStorage.removeItem(key);
    } else {
        deleteCookie(key);
    }
}

export function clearStorage() {
    if (isLocalStorageAvailable()) {
        localStorage.clear();
    } else {
        clearCookies();
    }
}

export function testCookie() {
    const domainName = process.env.VUE_APP_SHARED_COOKIE_DOMAIN;
    const key = process.env.VUE_APP_SHARED_COOKIE_KEY_LANGUAGE;
    
    const cookie2 = Cookies.get(key);
    console.log('cookie2: ', cookie2);
    const cookie3 = Cookies.set(`${key}_C3`, 'test_c3',{ 
        domain: `.${domainName}`,
        sameSite: 'None',
        secure: true});
    console.log('cookie3: ', cookie3);
    const cookie4 = Cookies.get(`${key}_C3`);
    console.log('cookie4: ', cookie4);
    const cookie5 = Cookies.set(`${key}_C5`, 'test_c5', { domain: domainName,
        sameSite: 'None',
        secure: true });
    console.log('cookie5: ', cookie5);

    const cookie1 = getCookie(key);
    console.log('cookie1: ', cookie1);
    const cookie6 = setCookie(`${key}_C6`, 'test_c6', 7);
    console.log('cookie6: ', cookie6);
    const cookie7 = getCookie(`${key}_C6`);
    console.log('cookie7: ', cookie7);

    document.cookie = `${key}_c8=test_c8; path=/; Secure; Domain=${domainName}`;
    const cookie8 = getCookie(`${key}_c8`);
    console.log('cookie8: ', cookie8);

    document.cookie = `${key}_c9=test_c9; path=/; Secure; Domain=.${domainName}`;
    const cookie9 = getCookie(`${key}_c9`);
    console.log('cookie9: ', cookie9);

}
<template>
    <v-container fluid>
        <v-breadcrumbs
            :items="breadcrumbsItems"
            class="pa-0 mb-2"
            large></v-breadcrumbs>
        <v-card outlined>
            <PayFunctionArea v-bind:isPay="payfunctionMap[functionId]"></PayFunctionArea>
            <v-card-title>
                <v-text-field
                    v-model="searchUserName"
                    append-icon="mdi-magnify"
                    :label="$t('student_list.search_student_label')"
                    single-line
                    @click:append="searchStudent"
                    clearable @click:clear="clearSearchUserName"
                    hide-details></v-text-field>
                <v-spacer></v-spacer>
            </v-card-title>
            <div class="mx-4">
                <v-divider></v-divider>
            </div>
            <v-data-table
                :headers="diaryHeaders"
                :items="diaryPosts"
                hide-default-footer
                disable-sort
                :server-items-length="totalPosts"
                :loading="loading"
                :page.sync="diaryPostPage"
                :options.sync="diaryPostOptions"
                :items-per-page="10">
                <template v-slot:item.unread="{ item }">
                    <v-icon v-if="item.unread" color="deep-orange darken-4">
                        mdi-circle-medium </v-icon>
                </template>
                <template v-slot:item.star="{ item }">
                    <div class="checkbox-container">
                        <v-checkbox v-model="item.star"
                            :on-icon="'mdi-star'"
                            :off-icon="'mdi-star-outline'"
                            color="deep-orange darken-4"
                            @change="addOrRemoveStarPost(item)"></v-checkbox>
                    </div>
                </template>
                <template v-slot:item.nameAndAccount="{ item }">
                    <span>{{ item.name }}</span>
                    <br />
                    <span class="font-weight-thin">{{ item.account }}</span>
                </template>
                <template #item.time_location="{ item }">
                    <span>{{ `${item.location}/${item.happenTime}` }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn small class="mr-2 mt-1 normal-case" outlined @click="dialogPostId = item.post_id; postDialogOpenCount += 1">
                        <v-icon small class="mr-1"> mdi-eye </v-icon>
                        {{ $t('button.view_post') }}
                    </v-btn>
                </template>
            </v-data-table>
            <div class="text-center my-4">
                <v-pagination
                    v-model="diaryPostPage"
                    :length="diaryPostPageCount"
                    circle
                    color="orange accent-2">
                </v-pagination>
            </div>
        </v-card>
        <PostDialog v-if="dialogPostId != null" v-bind:postDialogOpenCount="postDialogOpenCount" v-bind:postId="dialogPostId" v-bind:homeworkId="homeWorkId"></PostDialog>
    </v-container>
</template>

<script>
import { postCollectionServices } from '@/services/diaryPostCollection.js'
import PostDialog from '@/components/console/diary/component/PostDialog.vue'
import PayFunctionArea from '@/components/console/diary/component/PayFunctionArea.vue'
import {mapState } from "vuex";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getItem } from '@/utils/storageManager';

export default {
    name: 'collectionList',
    components: {PayFunctionArea, PostDialog },
    data() {
        return {
            functionId: 'f5',
            payfunctionMap: JSON.parse(getItem('payfunctionMap')),
            breadcrumbsItems: [],
            diaryHeaders: [
                {
                    text: '',
                    sortable: false,
                    value: 'unread',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: this.$t('table.collection'),
                    sortable: false,
                    value: 'star',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: this.$t('table.name_account'),
                    value: 'nameAndAccount',
                    sortable: false,
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: this.$t('table.diary_date'),
                    sortable: false,
                    value: 'diary_date',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: this.$t('table.post_title'),
                    sortable: false,
                    value: 'title',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: this.$t('table.location_time'),
                    sortable: false,
                    value: 'time_location',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: this.$t('table.create_date'),
                    sortable: false,
                    value: 'create_at',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: this.$t('table.function'),
                    sortable: false,
                    value: 'actions',
                    align: 'center',
                    class: 'grey lighten-4',
                }

            ],
            diaryPosts: [],
            tab: null,
            diaryPostPage: 1,
            diaryPostPageCount: 1,
            totalPosts: 0,
            loading: false,
            diaryPostOptions: {},
            searchUserName: '',
            homeWorkId: '',
            dialogPostId: null,
            postDialogOpenCount: 0,
        }
    },
    async created() {
        this.homeWorkId = this.$route.params.id;
        this.breadcrumbsItems = [
            {
                text: this.$t('index.title'),
                disabled: false,
                href: `/diary`,
            },
            {
                text: this.homeworkName,
                disabled: false,
                href: `/diary/homework/${this.homeWorkId}`,
            },
            {
                text: this.$t('menu.diary_collection'),
                disabled: true,
            },
        ];
        if (!this.payfunctionMap[this.functionId]) {
            this.homeWorkId = null;
            return;
        }
    },
    watch: {
        diaryPostOptions: {
            handler() {
                this.getDiaryPostData();
            },
            deep: true,
        },
        homeworkName() {
            this.breadcrumbsItems[1].text = this.homeworkName;
        },
    },
    computed: {
        ...mapState(["homeworkName"]),
    },
    methods: {
        getDiaryPostData() {
            if (this.homeWorkId == null) {
                return;
            }
            this.loading = true;
            this.getDiaryPostList().then(data => {
                this.diaryPosts = data.items;
                this.totalPosts = data.total;
                this.diaryPostPageCount = data.pageCount;
                this.loading = false;
                this.diaryPostPage = data.page;
            })
        },
        searchStudent() {
            if (this.homeWorkId == null)
                return;
            this.diaryPostOptions.page = 1;
            this.getDiaryPostData();
        },
        async getDiaryPostList() {
            const { page } = this.diaryPostOptions;
            const query = {
                page: page,
                user_name: this.searchUserName,
            };
            const res = await postCollectionServices.getPostCollectionList(
                this.homeWorkId, query);
            let items = [];
            dayjs.extend(utc);
            dayjs.extend(timezone);
            const tz = dayjs.tz.guess();
            for (let item of res.list) {
                items.push({
                    post_id: item.post.post_id,
                    diary_date: dayjs.utc(item.post.diary_date).tz(tz).format('YYYY-MM-DD'),
                    create_at: dayjs.utc(item.post.create_at).tz(tz).format('YYYY-MM-DD HH:mm:ss'),
                    title: item.post.title,
                    location: item.post.location,
                    happenTime: item.post.happenTime,
                    student_id: item.student_id,
                    name: item.student_name,
                    account: item.student_email,
                    star: true
                });
            }
            const total = res.total;
            const pageCount = res.totalPage;
            return { items, total, pageCount, page }
        },
        async addOrRemoveStarPost(post) {
            if (post.star) {
                await postCollectionServices.addPostToCollection(post.post_id);
            } else {
                await postCollectionServices.removePostFromCollection(post.post_id);
            }
            this.getDiaryPostData();
        },
        clearSearchUserName() {
            this.searchUserName = '';
            this.getDiaryPostData();
        },
    },
}
</script>

<style scoped></style>
<template>
    <v-app-bar id="app-bar" app
        elevate-on-scroll color="grey darken-3" flat clipped-left class="text-white">
        <v-btn
            class="mr-3 d-flex d-md-none white--text normal-case"
            text
            small
            v-if="showMenu"
            @click="setDrawer(!drawer)">
            <v-icon> mdi-menu </v-icon>
        </v-btn>

        <v-toolbar-title>
            <v-btn text plain class="white--text text-h5 pa-0 ma-0 normal-case"
                :to="getConsoleIndexUrl">MangaChat</v-btn>

        </v-toolbar-title>
        <v-chip outlined small color="white" class="ml-2">BETA</v-chip>
        <v-spacer />
        <!-- <v-btn class="ml-2 white--text" text :href="`/diary/diaryHomework/${this.homeworkId}`">
            <v-icon>mdi-close</v-icon>
        </v-btn> -->
        <v-menu
            bottom
            left
            offset-y
            origin="top right"
            transition="scale-transition"
            min-width="400">
            <template v-slot:activator="{ attrs, on }">
                <v-badge
                    :content="newMessageCount"
                    :value="newMessageCount"
                    color="red"
                    overlap>
                    <v-icon v-bind="attrs" v-on="on" @click="loadMessage" color="white" medium-large>mdi-bell</v-icon>
                </v-badge>
            </template>

            <v-list :tile="false" nav dense
                style="max-height: 350px"
                class="text-center overflow-y-auto">
                <v-list-item v-for="(message, index) in messages" :key="index"
                    @click="gotoMessageUrl(message)">
                    <v-list-item-content v-if="message.status == 1"
                        style="background-color: #FEFAF2;">
                        <v-row>
                            <v-col cols="2">
                                <v-list-item-avatar>
                                    <v-img :src=message.img></v-img>
                                </v-list-item-avatar>
                            </v-col>
                            <v-col cols="10" class="my-auto">
                                <v-list-item-title class="text-left">
                                    {{ message.msg }}
                                </v-list-item-title>
                                <v-list-item-subtitle
                                    class="text-left gray--text body-2">
                                    {{ message.during_time }}
                                </v-list-item-subtitle>
                            </v-col>
                        </v-row>
                    </v-list-item-content>
                    <v-list-item-content v-else>
                        <v-row>
                            <v-col cols="2">
                                <v-list-item-avatar>
                                    <v-img :src=message.img></v-img>
                                </v-list-item-avatar>
                            </v-col>
                            <v-col cols="10" class="my-auto">
                                <v-list-item-title class="text-left">
                                    {{ message.msg }}
                                </v-list-item-title>
                                <v-list-item-subtitle
                                    class="text-left gray--text body-2">
                                    {{ message.during_time }}
                                </v-list-item-subtitle>
                            </v-col>
                        </v-row>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <div style="background-color: white;" class="py-2">
                <v-btn text @click="loadMore" block
                    class="deep-orange--text text--darken-2 normal-case">
                    Show All
                </v-btn>
            </div>
            <!-- <div class="mx-auto deep-orange--text text--darken-3 text-center"
                @click="loadMore">
                Show All
            </div> -->
        </v-menu>
        <v-menu
            bottom
            left
            offset-y
            origin="top right"
            transition="scale-transition">
            <template v-slot:activator="{ attrs, on }">
                <v-btn
                    class="ml-2 white--text normal-case"
                    min-width="0"
                    text
                    v-bind="attrs"
                    v-on="on">
                    <v-icon>mdi-account</v-icon> <span class="d-done d-md-flex">{{ userName }}</span>
                </v-btn>
            </template>
            <v-list :tile="false" nav dense class="text-center">
                <v-list-item v-if="showSwitchMode">
                    <v-list-item-content>
                        <v-list-item-title>
                            <v-btn class="normal-case" block text to="/mode">{{ switchModeString }}</v-btn>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            <v-btn class="normal-case" block text href="/voucher">{{ $t('app_bar.voucher') }}</v-btn>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            <v-btn class="normal-case" block text href="/invite">{{ $t('app_bar.invite') }}</v-btn>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            <v-btn class="normal-case" block text href="/setting/user">{{ $t('app_bar.account_setting') }}</v-btn>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            <v-btn class="normal-case" block text href="/logout">{{ $t('app_bar.logout') }}</v-btn>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>
// Components
// Utilities
import { mapState, mapMutations, mapGetters } from "vuex";
//import { loginServices } from "@/services/adminLogin.js";
import { notificationServices } from "@/services/notification.js";
import { EventBus } from "@/utils/eventBus.js";
import { getItem } from "@/utils/storageManager.js";

export default {
    name: "AppBar",
    components: {},
    props: ['showMenu'],

    data: () => ({
        userName: getItem("userName"),
        homeworkId: '',
        newMessageCount: 0,
        messages: [],
        switchModeString: '',
        showSwitchMode: false,
    }),
    async created() {
        this.homeworkId = this.$route.params.id;
        await this.getUnreadNotificationCount();

        /// Setting for update badge count when receive notification
        EventBus.$on('updateBadgeCount', (newCount) => {
            this.newMessageCount += newCount;
        });

        const mode = sessionStorage.getItem('mode');
        if (mode === 't') {
            this.switchModeString = this.$t('app_bar.individual');
        } else {
            this.switchModeString = this.$t('app_bar.team');
        }
        this.showSwitchMode = sessionStorage.getItem('teamModeAvailable') === 'true';
    },
    computed: {
        ...mapState(["drawer"]),
        ...mapGetters(["getConsoleIndexUrl"]),
    },
    methods: {
        ...mapMutations({
            setDrawer: "SET_DRAWER",
        }),
        goBack() {
            location.href = `/diary/homework/${this.homeworkId}`
        },
        async getUnreadNotificationCount() {
            if (this.userName) {
                const result = await notificationServices.getUnreadNotificationCount();
                this.newMessageCount = result.count;
            }
        },
        async loadMessage() {
            if (this.messages.length > 0) {
                return;
            }
            const payload = {
                page: 1,
                type: ''
            }
            const result = await notificationServices.getIndex(payload);
            this.messages = result.dataList;
        },
        loadMore() {
            this.$router.push('/notification/')
        },
        async gotoMessageUrl(item) {
            console.log('item', item)
            if (item.redirect_url) {
                this.newMessageCount -= 1;
                await notificationServices.notificationRead(item.id);
                this.$router.push(item.redirect_url);
            }
        }
    },
};
</script>

<style scoped></style>
<template>
    <v-container fluid>
        <v-breadcrumbs
            :items="breadcrumbsItems"
            class="pa-0 mb-2"
            large></v-breadcrumbs>
        <v-card outlined>
            <PayFunctionArea v-bind:isPay="payfunctionMap[functionId]"></PayFunctionArea>
            <v-card-title>
                <v-text-field
                    v-model="searchUserName"
                    append-icon="mdi-magnify"
                    :label="$t('student_list.search_student_label')"
                    single-line
                    @click:append="searchStudent"
                    clearable @click:clear="clearSearchUserName"
                    hide-details></v-text-field>
                <v-spacer></v-spacer>
            </v-card-title>
            <div class="mx-4">
                <v-divider></v-divider>
            </div>
            <v-subheader>{{ formatePosts() }}</v-subheader>

            <v-data-table
                :headers="headers"
                :items="posts"
                :server-items-length="totalPosts"
                :loading="loading"
                :page.sync="postPage"
                :options.sync="postOptions"
                :items-per-page="10"
                hide-default-footer
                disable-sort
                class="mx-4">

                <template v-slot:item.unread="{ item }">
                    <v-icon v-if="item.unread" color="deep-orange darken-4">
                        mdi-circle-medium </v-icon>
                </template>

                <template v-slot:item.nameAndAccount="{ item }">
                    <span>{{ item.user_name }}</span>
                    <br />
                    <span class="font-weight-thin">{{ item.account }}</span>
                </template>

                <template #item.time_location="{ item }">
                    <span>{{ `${item.location}/${item.happenTime}` }}</span>
                </template>

                <template v-slot:item.actions="{ item }">
                    <v-btn small class="mr-2 mt-1 normal-case" outlined
                        @click="dialogPostId = item.post_id; postDialogOpenCount += 1">
                        <v-icon small class="mr-1"> mdi-eye </v-icon>
                        {{ $t('button.view_post') }}{{ item.id }}
                    </v-btn>
                </template>
            </v-data-table>
            <div class="text-center my-4">
                <v-pagination
                    v-model="postPage"
                    :length="postPageCount"
                    circle
                    color="orange accent-2">
                </v-pagination>
            </div>
        </v-card>
        <PostDialog v-if="dialogPostId != null" v-bind:postDialogOpenCount="postDialogOpenCount"
            v-bind:postId="dialogPostId"
            v-bind:homeworkId="homeWorkId"></PostDialog>
    </v-container>
</template>

<script>

import { studentServices } from '@/services/student.js';
import { emotion as emotionTable, behavior as behaviorTable } from '@/locale/i18n/zh_tw.json';
import PayFunctionArea from '@/components/console/diary/component/PayFunctionArea.vue';
import PostDialog from '@/components/console/diary/component/PostDialog.vue'
import { mapState } from "vuex";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getItem } from '@/utils/storageManager';

export default {
    name: 'studentList',
    components: { PayFunctionArea, PostDialog },
    data() {
        return {
            functionId: 'f6',
            payfunctionMap: JSON.parse(getItem('payfunctionMap')),
            dialogPostId: null,
            postDialogOpenCount: 0,
            breadcrumbsItems: [],
            headers: [
                {
                    text: '',
                    value: 'unread',
                    sortable: false,
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: this.$t('table.diary_date'),
                    sortable: false,
                    value: 'diary_date',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: this.$t('table.name_account'),
                    value: 'nameAndAccount',
                    sortable: false,
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: this.$t('table.post_title'),
                    sortable: false,
                    value: 'title',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: this.$t('table.location_time'),
                    sortable: false,
                    value: 'time_location',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: this.$t('table.create_date'),
                    sortable: false,
                    value: 'create_at',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: this.$t('table.function'), value: 'actions', sortable: false,
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                }
            ],
            posts: [
                {
                    role: '',
                    name: '',
                    studentId: '',
                    lastTitle: '',
                    emotions: '',
                    action: '',
                    lastLoginTime: '3/3/2020 7:29:16 PM',
                    watching: true,
                },
            ],
            postPage: 1,
            postPageCount: 1,
            totalPosts: 0,
            loading: false,
            postOptions: {},
            homeWorkId: '',
            searchUserName: '',
        }
    },
    async created() {
        this.homeWorkId = this.$route.params.id;
        this.breadcrumbsItems.push(
            {
                text: this.$t('index.title'),
                disabled: false,
                href: `/diary`,
            },
            {
                text: this.homeworkName,
                disabled: false,
                href: `/diary/homework/${this.homeWorkId}`,
            },
            {
                text: this.$t('menu.diary_returned'),
                disabled: true,
            }
        );
        if (!this.payfunctionMap[this.functionId]) {
            this.homeWorkId = null;
            return;
        }
    },
    watch: {
        postOptions: {
            handler() {
                this.getPostData()
            },
            deep: true,
        },
        homeworkName() {
            this.breadcrumbsItems[1].text = this.homeworkName;
        },
    },
    computed: {
        ...mapState(["homeworkName"]),
    },
    methods: {
        getPostData() {
            if (this.homeWorkId == null) {
                return;
            }
            this.loading = true;
            this.getPostList().then(data => {
                this.posts = data.items;
                this.totalPosts = data.total;
                this.postPageCount = data.pageCount;
                this.loading = false;
                this.postPage = data.page;
            })
        },
        searchStudent() {
            if (this.homeWorkId == null)
                return;
            this.postOptions.page = 1;
            this.getPostData();
        },
        async getPostList() {
            const { page } = this.postOptions;
            const query = {
                page: page,
                user_name: this.searchUserName,
            };
            const res = await studentServices.getSendBackPosts(this.homeWorkId, query);
            dayjs.extend(utc);
            dayjs.extend(timezone);
            const tz = dayjs.tz.guess();
            const items = res.list.map((item) => {
                return {
                    ...item,
                    create_at: dayjs.utc(item.create_at).tz(tz).format('YYYY/MM/DD HH:mm:ss'),
                    diary_date: dayjs.utc(item.diary_date).tz(tz).format('YYYY/MM/DD'),
                }
            })
            const total = res.total;
            const pageCount = res.totalPage;
            return { items, total, pageCount, page }
        },
        formatEmotions(emotions) {
            if (Array.isArray(emotions) && typeof emotions[0] === 'string') {
                let result = '';
                for (let emotion of emotions) {
                    const a = emotionTable.type[emotion];
                    if (a) {
                        if (result.length > 0) {
                            result += ', ';
                        }
                        result += a;
                    }
                }
                return result;
            }
            return emotions;
        },
        formatBehavior(behavior) {
            const a = behaviorTable.type[behavior];
            if (a) {
                return a;
            }
            return '';
        },
        async addOrRemoveCase(student) {
            // console.log('student:', student)
            const homeWorkId = parseInt(this.homeWorkId);
            if (student.watching) {
                //Add case
                await studentServices.addStudentCase(homeWorkId, student.id);
            } else {
                //Remove case
                await studentServices.deleteStudentCase(homeWorkId, student.id);
            }
        },
        clearSearchUserName() {
            this.searchUserName = '';
            this.getPostData();
        },
        formatePosts() {
            return this.$t('unit_type.post_total').replace('${num}', this.totalPosts);
        }
    },
}
</script>

<style scoped></style>
<template>
    <v-container>
        <v-row class="vh-100 align-center align-self-center">
            <v-col>
                <v-progress-linear color="amber darken-3" indeterminate rounded height="6"></v-progress-linear>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { loginServices } from "@/services/login.js";
import { diaryServices } from '@/services/diary.js';
// import { checkLocalStorage } from '@/utils/storageUtils';
// import { setCookie, getCookie } from '@/utils/cookieUtils';
import { getItem, setItem } from '@/utils/storageManager';
export default {
    name: "LoginCheck",
    async created() {
        
            const userName = getItem('userName');
            console.log('userName in loginCheck: ', userName)
            
            /// mangachat 登入
            // if (!userName) {
            //     loginServices.oauthLogin();
            // } else {
            //     let toRedirectPath = sessionStorage.getItem('redirect_path');
            //     if (!toRedirectPath || toRedirectPath === '/console/login'
            //         || toRedirectPath === '/login/check') {
            //         toRedirectPath = '/diary';
            //     }
            //     const res = await loginServices.login();
            //     if (res.success) {
            //         if (!res.contactEmailVerified) {
            //             location.href = '/setting/email';
            //         } else if (res.status != '1') {
            //             location.href = '/setting/basicInfo';
            //         }
            //         let payfunctionMap = {};
            //         const funcRes = await diaryServices.getDiaryFunctionPrivilege();
            //         funcRes.data.forEach(func => {
            //             payfunctionMap[`f${func['id']}`] = func['is_pay'];
            //         });
            //         localStorage.setItem('payfunctionMap', JSON.stringify(payfunctionMap));
            //         sessionStorage.removeItem('redirect_path');
            //         this.$router.push(toRedirectPath);
            //     } else {
            //         // this.$router.push('/console/login');
            //         // location.href = '/login'
            //         loginServices.oauthLogin();
            //     }
            // }
            /// 後台自己登入
            if (!userName) {
                location.href = '/login'
            } else {
                let toRedirectPath = sessionStorage.getItem('redirect_path');
                if (!toRedirectPath || toRedirectPath === '/console/login'
                    || toRedirectPath === '/login/check') {
                    toRedirectPath = '/diary';
                }
                const res = await loginServices.login();
                if (res.success) {
                    let payfunctionMap = {};
                    const funcRes = await diaryServices.getDiaryFunctionPrivilege();
                    funcRes.data.forEach(func => {
                        payfunctionMap[`f${func['id']}`] = func['is_pay'];
                    });
                    setItem('payfunctionMap', JSON.stringify(payfunctionMap));
                    if (!res.contactEmailVerified) {
                        setItem('emailVerified', '0');
                        setItem('infoSet', '0');
                        location.href = '/setting/email';
                    } else if (res.status != '1') {
                        setItem('infoSet', '0');
                        location.href = '/setting/basicInfo';
                    } else {
                        sessionStorage.removeItem('redirect_path');
                        this.$router.push(toRedirectPath);
                    }
                } else {
                    location.href = '/login'
                }
            }
        
    },
    mounted() {
        const facebookChat = document.querySelector(".fb_reset");
        if (facebookChat)
            facebookChat.style.display = "none";
    },
};
</script>

<style scoped>
.vh-100 {
    height: 100vh;
}
</style>
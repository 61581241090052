import Vue from 'vue'
import VueRouter from 'vue-router'

import consoleLogin from '@/components/console/Login'
import loginRedirect from '@/components/console/Redirect'
import oAuth3rdRedirect from '@/components/account/Oauth3rdRedirect'
import Index from '@/components/Index'

import LoginCheckIndex from '@/components/console/LoginCheck'
import logout from '@/components/console/Logout'
import manage from '@/components/console/Manage'
import mode from '@/components/console/Mode'
import myTeam from '@/components/console/MyTeam'
import userSetting from '@/components/console/UserSetting'
import refresh from '@/components/console/Refresh'
import voucher from '@/components/console/Voucher'
import invite from '@/components/console/Invite'
import student from '@/components/console/Student'
import invitationCode from '@/components/console/InvitationCode'

import basicInfoSetting from '@/components/account/BasicInfoSetting'
import emailVerify from '@/components/account/EmailVerify'
import resetPwd from '@/components/account/ResetPwd'

import diaryMain from '@/components/console/main/Diary'
import diarySample from '@/components/console/main/DiarySample'
import DiarySampleResourceList from '@/components/console/main/DiarySampleResourceList'
import DiarySampleResource from '@/components/console/diary/DiarySampleResource'
import diaryQuest from '@/components/console/diary/Quest'
import diaryQuestEdit from '@/components/console/diary/QuestEdit'
import SampleHomeworkEdit from '@/components/console/diary/SampleHomeworkEdit'

import HomeworkEditIndex from '@/components/console/diary/HomeworkEdit'

import diaryReportIndex from '@/components/console/diary/Report'
import diaryNewsIndex from '@/components/console/diary/News'
import diaryInfoIndex from '@/components/console/diary/Info'

import diaryResourceIndex from '@/components/console/diary/Resource'

import diaryRecordExport from '@/components/console/diary/export/Record'

import studentList from '@/components/console/diary/StudentList'
import studentDetail from '@/components/console/diary/StudentDetail'
import diaryCollection from '@/components/console/diary/Collection'
import diaryCases from '@/components/console/diary/Cases'
import diarySendBack from '@/components/console/diary/SendBack'
import diaryPostRevise from '@/components/console/diary/PostRevise'
import diaryPost from '@/components/console/diary/Post'

import diaryQuestList from '@/components/console/diary/HomeworkQuestList'
import diaryQuestListEdit from '@/components/console/diary/HomeworkQuestListEdit'
import diaryQuestNews from '@/components/console/diary/HomeworkQuestNews'
import contestHomeworkEdit from '@/components/contest/ContestHomeworkEdit'
import contestInfoIndex from '@/components/contest/ContestInfo'
import contestRanking from '@/components/contest/ContestRanking'


import userChargePlanList from '@/components/userPay/PlanList'

import notifiactionList from '@/components/console/NotificationList'

import getEbookByHomeworkId from '@/components/ebook/CheckBook'
import ebookBasicInfo from '@/components/ebook/BasicInfo'
import ebookPageList from '@/components/ebook/PageList'
import ebookPageLayoutEdit from '@/components/ebook/PageLayoutEdit'
import ebookPosts from '@/components/ebook/PostsSelection'
import ebookPrint from '@/components/ebook/Print'
import ebookLoginCheck from '@/components/ebook/BookLoginCheck'
import ebookRedirect from '@/components/ebook/BookRedirect'
import book from '@/components/ebook/BookList'
import ebookLogout from '@/components/ebook/BookLogout'

import NotFound from '@/components/NotFound'
import ErrorPage from '@/components/ErrorPage'
//import store from '@/store'
// import { getItem, checkSharedLangCookie } from '@/utils/storageManager';
import { getItem } from '@/utils/storageManager';

Vue.use(VueRouter)

const routes = [
    { path: '/', redirect: '/index' },
    {
        path: '/index',
        name: 'Index',
        component: Index,
        meta: {
            layout: 'default',
            requiresAuth: true,
            requiresAuthType: 'index'
        }
    },

    {
        path: '/login',
        name: 'consoleLogin',
        component: consoleLogin,
        meta: {
            layout: 'default'
        }
    },
    {
        path: '/setting/basicInfo',
        name: 'basicInfoSetting',
        component: basicInfoSetting,
        meta: {
            layout: 'default',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },
    {
        path: '/setting/email',
        name: 'emailVerify',
        component: emailVerify,
        meta: {
            layout: 'default',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },
    {
        path: '/oauth/login/:platform/redirect',
        name: 'oAuth3rdLoginRedirect',
        component: oAuth3rdRedirect,
    },
    {
        path: '/pwd/reset/:code',
        name: 'resetPwd',
        component: resetPwd,
        meta: {
            layout: 'default'
        }
    },
    {
        path: '/login/redirect',
        name: 'loginRedirect',
        component: loginRedirect,
        meta: {
            layout: 'default'
        }
    },
    {
        path: '/logout',
        name: 'logout',
        component: logout,
        meta: {
            layout: 'default',
        }
    },

    {
        path: '/login/check',
        name: 'LoginCheckIndex',
        component: LoginCheckIndex,
        meta: {
            layout: 'default',
        }
    },

    {
        path: '/diary',
        name: 'DiaryMain',
        component: diaryMain,
        meta: {
            layout: 'consoleMain',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/diarySample',
        name: 'DiarySample',
        component: diarySample,
        meta: {
            layout: 'consoleMain',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/diarySample/new',
        name: 'SampleHomeworkNew',
        component: SampleHomeworkEdit,
        meta: {
            layout: 'consoleMain',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/diarySample/:id/edit',
        name: 'SampleHomeworkEdit',
        component: SampleHomeworkEdit,
        meta: {
            layout: 'consoleMain',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/diarySample/resourcesBook',
        name: 'DiarySampleResourceList',
        component: DiarySampleResourceList,
        meta: {
            layout: 'consoleMain',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/diarySample/resourcesBook/:id/resources',
        name: 'DiarySampleResource',
        component: DiarySampleResource,
        meta: {
            layout: 'consoleMain',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/diary/homework/new',
        name: 'HomeworkNewIndex',
        component: HomeworkEditIndex,
        meta: {
            layout: 'consoleNoMenu',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/diary/homework/:id/edit',
        name: 'HomeworkEditIndex',
        component: HomeworkEditIndex,
        meta: {
            layout: 'consoleNoMenu',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/diary/homework/:id/report',
        name: 'diaryReportIndex',
        component: diaryReportIndex,
        meta: {
            layout: 'console',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/diary/homework/:id/student/:uid/report',
        name: 'userDiaryReportIndex',
        component: diaryReportIndex,
        meta: {
            layout: 'console',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/diary/homework/:id/info',
        name: 'diaryInfoIndex',
        component: diaryInfoIndex,
        meta: {
            layout: 'console',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },
    { path: '/diary/homework/:id', redirect: '/diary/homework/:id/news' },
    {
        path: '/diary/homework/:id/news',
        name: 'diaryNewsIndex',
        component: diaryNewsIndex,
        meta: {
            layout: 'console',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/diary/homework/:id/resource',
        name: 'diaryResourceIndex',
        component: diaryResourceIndex,
        meta: {
            layout: 'console',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/diary/homework/:id/export',
        name: 'diaryRecordExport',
        component: diaryRecordExport,
        meta: {
            layout: 'console'
            // ,
            // requiresAuth: true,
            // requiresAuthType: 'console'
        }
    },

    {
        path: '/diary/homework/:id/student',
        name: 'students',
        component: studentList,
        meta: {
            layout: 'console',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/diary/homework/:id/student/:sid',
        name: 'studentDetail',
        component: studentDetail,
        meta: {
            layout: 'console',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/diary/homework/:id/collection',
        name: 'collection',
        component: diaryCollection,
        meta: {
            layout: 'console',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/diary/homework/:id/case',
        name: 'case',
        component: diaryCases,
        meta: {
            layout: 'console',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/diary/homework/:id/sendback',
        name: 'sendback',
        component: diarySendBack,
        meta: {
            layout: 'console',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/diary/homework/:id/quest',
        name: 'diaryQuest',
        component: diaryQuest,
        meta: {
            layout: 'consoleMain',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/diary/homework/:id/quest/edit',
        name: 'diaryQuestEdit',
        component: diaryQuestEdit,
        meta: {
            layout: 'consoleMain',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/diary/homework/:id/questList',
        name: 'diaryQuestList',
        component: diaryQuestList,
        meta: {
            layout: 'console',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/diary/homework/:id/questList/edit',
        name: 'diaryQuestListEdit',
        component: diaryQuestListEdit,
        meta: {
            layout: 'consoleNoMenu',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/diary/homework/:id/questList/:qid',
        name: 'diaryQuestNews',
        component: diaryQuestNews,
        meta: {
            layout: 'console',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/diary/diaryPost/:id/revise',
        name: 'diaryPostRevise',
        component: diaryPostRevise,
        meta: {
            layout: 'consoleNoMenu',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/diary/diaryPost/:id',
        name: 'diaryPost',
        component: diaryPost,
        meta: {
            layout: 'consoleNoMenu',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/contest/:id/edit',
        name: 'contestHomeworkEdit',
        component: contestHomeworkEdit,
        meta: {
            layout: 'consoleNoMenu',
            requiresAuth: true,
            requiresAuthType: 'console'
        },
        props: {
            default: true,
        },
    },

    {
        path: '/contest/:id/ranking',
        name: 'contestRanking',
        component: contestRanking,
        meta: {
            layout: 'consoleNoMenu',
        }
    },

    {
        path: '/diary/homework/:id/contestinfo',
        name: 'contestInfoIndex',
        component: contestInfoIndex,
        meta: {
            layout: 'console',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/notification',
        name: 'notificationList',
        component: notifiactionList,
        meta: {
            layout: 'consoleMain',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/mode',
        name: 'mode',
        component: mode,
        meta: {
            layout: 'consoleMain',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/team/my',
        name: 'myTeam',
        component: myTeam,
        meta: {
            layout: 'consoleMain',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/setting/user',
        name: 'userSetting',
        component: userSetting,
        meta: {
            layout: 'consoleMain',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/refresh',
        name: 'Refresh',
        component: refresh,
        meta: {
            layout: 'default',
        }
    },

    {
        path: '/voucher',
        name: 'voucher',
        component: voucher,
        meta: {
            layout: 'consoleMain',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/invite',
        name: 'invite',
        component: invite,
        meta: {
            layout: 'consoleMain',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/drpb/:code',
        name: 'invitationCode',
        component: invitationCode,
        meta: {
            layout: 'consoleNoMenu',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/student',
        name: 'student',
        component: student,
        meta: {
            layout: 'consoleMain',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/my/ucp',
        name: 'ucp',
        component: userChargePlanList,
        meta: {
            layout: 'consoleMain',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/diary/homework/:id/student/:sid/ebook',
        name: 'getConsoleEbookByHomeworkId',
        component: getEbookByHomeworkId,
        meta: {
            layout: 'ebook',
            requiresAuth: true,
            requiresAuthType: 'ebook'
        }
    },

    {
        path: '/ebook/logout',
        name: 'ebookLogout',
        component: ebookLogout,
        meta: {
            layout: 'default',
        }
    },

    {
        path: '/ebook/student/:did',
        name: 'getEbookByHomeworkId',
        component: getEbookByHomeworkId,
        meta: {
            layout: 'ebook',
            requiresAuth: true,
            requiresAuthType: 'ebook'
        }
    },
    {
        path: '/ebook/:diaryPrintId/basicinfo',
        name: 'ebookBasicInfo',
        component: ebookBasicInfo,
        meta: {
            layout: 'ebook',
            requiresAuth: true,
            requiresAuthType: 'ebook'
        }
    },
    {
        path: '/ebook/:diaryPrintId/list',
        name: 'ebookPageList',
        component: ebookPageList,
        meta: {
            layout: 'ebook',
            requiresAuth: true,
            requiresAuthType: 'ebook'
        }
    },

    {
        path: '/ebook',
        name: 'book',
        component: book,
        meta: {
            layout: 'ebook',
            requiresAuth: true,
            requiresAuthType: 'ebook'
        }
    },
    {
        path: '/ebook/:diaryPrintId/edit',
        name: 'ebookPageEdit',
        component: ebookPageLayoutEdit,
        meta: {
            layout: 'ebook',
            requiresAuth: true,
            requiresAuthType: 'ebook'
        }
    },
    {
        path: '/ebook/:diaryPrintId/posts',
        name: 'ebookPostsEdit',
        component: ebookPosts,
        meta: {
            layout: 'ebook',
            requiresAuth: true,
            requiresAuthType: 'ebook'
        }
    },

    {
        path: '/ebook/login',
        name: 'ebookLogin',
        component: ebookLoginCheck,
        meta: {
            layout: 'default'
        }
    },

    {
        path: '/ebook/redirect',
        name: 'ebookRedirect',
        component: ebookRedirect,
        meta: {
            layout: 'default'
        }
    },

    {
        path: '/ebook/:diaryPrintId/download',
        name: 'ebookPrint',
        component: ebookPrint,
        meta: {
            layout: 'ebook',
            requiresAuth: true,
            requiresAuthType: 'ebook'
        }
    },

    {
        path: '/manage',
        name: 'manage',
        component: manage,
        meta: {
            layout: 'consoleNoMenu',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/404',
        name: 'NotFound',
        component: NotFound,
        meta: {
            layout: 'portal'
        }
    },
    {
        path: '/error',
        component: ErrorPage,
        meta: {
            layout: 'portal'
        }
    },
    {
        path: '*',
        name: 'PageNotFound',
        component: NotFound,
        meta: {
            layout: 'portal'
        }
    },
]
// .map(route => ({ ...route, path: '/v2' + route.path }))

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    // 如果 router 轉跳的頁面需要驗證 requiresAuth: true
    if (to.matched.some(record => {
        // console.log(record.name, record.meta.requiresAuth);
        return record.meta.requiresAuth;
    })) {
        const authType = to.meta.requiresAuthType;

        let paramUser = 'userName';
        let paramLogin = '/login/check';
        
        if (authType == 'ebook') {
            paramUser = 'ebookUserName';
            paramLogin = '/ebook/login';
        }

        let flag1 = getItem(paramUser);
        if (authType == 'index') {
            if (!flag1) {
                next();
                return;
            } else {
                next({ path: '/diary' });
                return;
            }
        }
        if (to.fullPath.indexOf('/setting/email') == -1 && to.fullPath.indexOf('/setting/basicInfo') == -1) {
            let emailVerified = getItem('emailVerified');
            let infoSet = getItem('infoSet');
            if (emailVerified && emailVerified == '0') {
                sessionStorage.setItem('redirect_path', to.fullPath);
                next({ path: '/setting/email' });
            } else if (infoSet && infoSet == '0' || flag1 == 'null') {
                sessionStorage.setItem('redirect_path', to.fullPath);
                next({ path: '/setting/basicInfo' });
            }
        }

        if (!flag1) {

            // 轉跳到 login page
            // console.log('轉跳到 login page, to.fullPath:', to.fullPath)
            sessionStorage.setItem('redirect_path', to.fullPath);
            // console.log('to.fullPath1:', sessionStorage.getItem('redirect_path'))
            next({ path: paramLogin });
        }

        /// Check if the lang stored in the local storage is the same as the one in the cookie
        /// Go to refresh page if not the same
        // if (to.fullPath.indexOf('/refresh') == -1) {
        //     if (!checkSharedLangCookie()) {
        //         next({ path: '/refresh' });
        //     }
        // }
        next(); //成功可以進去
    } else {
        // console.log('next')
        next(); // 往下繼續執行
    }
});

export default router;
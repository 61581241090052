<template>
    <v-container fluid>
        <v-breadcrumbs :items="breadcrumbsItems" class="pa-0 mb-3" large></v-breadcrumbs>
        <Post
            v-if="showPost"
            v-bind:postItem="postItem"
            v-bind:commentList="commentList"
            v-bind:homeworkId="homeworkId"
            v-bind:key="postItem.id" />
    </v-container>
</template>

<script>
import Post from '@/components/console/diary/component/Post.vue'
import { diaryPostServices } from "@/services/diaryPost.js";
import { diaryServices } from "@/services/diary.js";
export default {
    name: 'diaryPost',
    components: {
        Post
    },
    data() {
        return {
            breadcrumbsItems: [],
            postId: '',
            homeworkId: '',
            homeworkName: '',
            studentName: '',
            postTitle: '',
            postItem: null,
            commentList: [],
            showPost: false,
            studentId: '',
        }
    },
    async created() {
        this.postId = this.$route.params.id;

        console.log('postId', this.postId)

        const result = await diaryPostServices.getHomeworkPost(this.postId);
        this.commentList = result.data.comment;
        this.postItem = result.data.post;
        this.homeworkId = result.data.homework_id;
        this.studentId = result.data.post.creator_id;
        this.studentName = result.data.post.user_name;
        this.postTitle = result.data.post.title;
        const diaryHomeworkNameRes = await diaryServices.getHomeworkName(this.homeworkId, {});
        this.homeworkName = diaryHomeworkNameRes.data.name;
        /*
        Notification List
        Journals
        我的心情日記
        Student List
        pj
        發現問題
        */
        this.breadcrumbsItems = [
            {
                text: 'Notification List',
                disabled: false,
                href: `/notification`,
            },
            {
                text: 'Journals',
                disabled: false,
                href: `/diary`,
            },
            {
                text: this.homeworkName,
                disabled: false,
                href: `/diary/homework/${this.homeworkId}`,
            },
            {
                text: 'Student List',
                disabled: false,
                href: `/diary/homework/${this.homeworkId}/student`,
            },

            {
                text: this.studentName,
                disabled: false,
                href: `/diary/homework/${this.homeworkId}/student/${this.studentId}`,
            },
            {
                text: this.postTitle,
                disabled: true,
            },
        ];
        this.showPost = true;
        console.log(result);
    },
    method: {

    }
}
</script>

<style scoped></style>